<template>
  <span class="text">
    <slot />
  </span>
</template>

<style lang="scss" scoped>
.text,
.text :deep(*) {
  @apply tw-font-sans tw-text-base tw-font-medium tw-text-orange-500;
}
</style>
